<template>
  <CDataTable
    :items="computedItems"
    :fields="fields"
    hover
    :items-per-page-select="{label: 'Einträge pro Seite'}"
    :items-per-page="25"
    column-filter
    sorter
    pagination
  >
    <template #no-items-view><NoData/></template>

    <template #actionbar="{item}">
      <td nowrap="" width="1%">
        <CButton
          color="primary"
          variant="outline"
          square
          size="sm"
          v-c-tooltip="{content: 'Editieren', placement:'left'}"
          v-on:click="editEntry(item.kundenid)"
        >
          <CIcon name="cil-highlighter"/>
        </CButton>
        <CButton
          color="success"
          variant="outline"
          size="sm"
          class="ml-2"
          v-c-tooltip="{content: 'Inaktiv setzen', placement:'left'}"
          v-on:click="toggleAktiv(item.kundenid)"
          v-if="item.aktiv"
        >
          <CIcon name="cil-lock-unlocked"/>
        </CButton>
        <CButton
          color="danger"
          variant="outline"
          size="sm"
          class="ml-2"
          v-c-tooltip="{content: 'Aktiv setzen', placement:'left'}"
          v-on:click="toggleAktiv(item.kundenid)"
          v-if="!item.aktiv"
        >
          <CIcon name="cil-lock-locked"/>
        </CButton>
        <CButton
          color="danger"
          variant="outline"
          size="sm"
          class="ml-2"
          v-c-tooltip="{content: 'Löschen', placement:'left'}"
          v-on:click="deleteEntry(item.kundenid)"
          v-if="!item.aktiv"
        >
          <CIcon name="cil-trash"/>
        </CButton>
      </td>
    </template>
  </CDataTable>
</template>

<script>
import Vue from 'vue'
import NoData from '@/components/NoData'

export default {
  name: 'KundenTable',
  props: {
    kunden: Array,
    value: String
  },
  components: {
    NoData
  },
  data () {
    return {
      fields: [
        {
          key: 'kdnr',
          label: 'Kundennummer'
        },
        {
          key: 'name',
          label: 'Name'
        },
        {
          key: 'strasse',
          label: 'Strasse'
        },
        {
          key: 'ort',
          label: 'Ort'
        },
        {
          key: 'actionbar',
          label: '',
          _style: 'width:1%',
          sorter: false,
          filter: false
        }
      ]
    }
  },
  mounted () {
  },
  computed: {
    computedItems () {
      if (this.kunden) {
        if (this.kunden.length > 0) {
          return this.kunden.map(kd => {
            const result = {
              kundenid: kd.kundenid ? kd.kundenid : kd.kundenid,
              aktiv: kd.aktiv,
              kdnr: kd.kdnr || '-',
              name: kd.name1,
              strasse: kd.strasse + ' ' + kd.hausnr,
              ort: kd.plz + ' ' + kd.ort
            }

            return result
          })
        } else {
          return []
        }
      } else {
        return []
      }
    }
  },
  methods: {
    editEntry (kundenid) {
      this.$router.push({ path: `/kunden/editieren/${kundenid}` })
    },
    toggleAktiv: function (kundenid) {
      const self = this
      const options = {
        okText: 'Ja',
        cancelText: 'Nein'
      }
      self.$dialog
        .confirm('Den Status des Kunden wirklich ändern?', options)
        .then((dialog) => {
          Vue.axios.put('/kunden/toggle/aktiv/' + kundenid)
            .then(function (response) {
              self.$snotify.success('Der Status des Kunden wurde geändert.', {
                position: 'rightTop',
                timeout: 4000
              })
              self.$emit('input', kundenid)
            })
        })
        .catch(() => {
          // Do Nothing
        })
    },
    deleteEntry: function (kundenid) {
      const self = this
      const options = {
        okText: 'Ja',
        cancelText: 'Nein'
      }
      this.$dialog
        .confirm('Den Kunden wirklich löschen?', options)
        .then((dialog) => {
          Vue.axios.delete('/kunden/delete/' + kundenid)
            .then((response) => {
              self.$snotify.error('Der Kunde wurde gelöscht.', {
                position: 'rightTop',
                timeout: 4000
              })
              self.$emit('input', kundenid)
            })
        })
        .catch(() => {
          // Do Nothing
        })
    }
  }
}
</script>
